var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("sidebar-menu", { attrs: { id: "sidebar" } }),
      _c(
        "div",
        { staticClass: "main-wrapper" },
        [
          _c("div", { staticClass: "navbar" }),
          _vm.crumbs.length > 1
            ? _c("b-breadcrumb", { attrs: { items: _vm.crumbs } })
            : _vm._e(),
          !_vm.$root.$data.loggedIn || _vm.$root.$data.fieldDefs != null
            ? _c("router-view", { staticClass: "mt-4" })
            : _vm._e(),
          _vm.$root.$data.loggedIn && _vm.$root.$data.fieldDefs == null
            ? _c("b-spinner", { attrs: { label: "Spinning" } })
            : _vm._e(),
          _c("footer", { staticClass: "mb-5" })
        ],
        1
      ),
      _c("notifications", {
        staticClass: "boep-notification",
        attrs: { position: "top center" }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }