<template>
  <div>
    <sidebar-menu id="sidebar"></sidebar-menu>

    <div class="main-wrapper">
      <div class="navbar"></div>

      <b-breadcrumb v-if="crumbs.length > 1" :items="crumbs"></b-breadcrumb>

      <router-view class="mt-4" v-if="!$root.$data.loggedIn || $root.$data.fieldDefs != null"></router-view>
      <b-spinner v-if="$root.$data.loggedIn && $root.$data.fieldDefs == null" label="Spinning"></b-spinner>

      <footer class="mb-5">
      </footer>
    </div>

    <notifications class="boep-notification" position="top center" />
  </div>
</template>

<script>
  const SidebarMenu = () => import('@/views/SidebarMenu')

  export default {
    name: 'DefaultContainer',
    components: {
      SidebarMenu,
    },
    computed: {
      crumbs: function() {
        return [{ to: { name: 'dashboard' }, text: 'Home'}].concat(this.$root.$data.crumbs)
      }
    },
  }
</script>

<style lang="scss" scoped>
  @import '@/assets/scss/variables';

  .main-wrapper {
    margin-left: $sidebar-width;
  }

  .navbar {
    height: $navbar-height;
    background-color: $color-dark-purple;
  }

  b-breadcrumb {
    border-radius: 0;
  }

  footer {
    margin-top: 2em;
    border-top: 1px solid #eee;
    padding-top: 1em;
    text-align: center;

    img {
      width: 100px;
    }
  }
</style>